import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-115c4862"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "v-project-vue" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_view = _resolveComponent("page-view")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.projectData)
      ? (_openBlock(), _createBlock(_component_page_view, { key: 0 }, {
          default: _withCtx(() => [
            (this.projectContent)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("h1", null, _toDisplayString(_ctx.projectContent.project_title), 1),
                  _createElementVNode("div", {
                    innerHTML: _ctx.projectContent.description_EN.value
                  }, null, 8, _hoisted_2)
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}